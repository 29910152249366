import { createApp } from 'vue'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Image from 'primevue/image'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCat, faMugHot, faCocktail, faAt, faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faXingSquare, faLinkedin, faPaypal } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

library.add(faCat, faMugHot, faCocktail, faAt, faLanguage, faXingSquare, faLinkedin, faPaypal)

const app = createApp(App)

app.use(VueAxios, axios)

// awesome font icons
app.component('font-awesome-icon', FontAwesomeIcon)

// primevue components
app.use(PrimeVue)
app.component('Button', Button)
app.component('Dialog', Dialog)
app.component('Image', Image)

// mount app
app.mount('#app')
