
import { Options, Vue } from 'vue-class-component'

@Options({
})

export default class BusinessCard extends Vue {
  displayDialog = false
  catImage = require('./../assets/placeholder_400.png')

  mounted (): void {
    console.log('mounted')
    this.loadCat()
  }

  releaseTheCat (): void {
    this.displayDialog = true
    // this.loadCat()
  }

  loadCat (size = 400): void {
    console.log('load new cat')
    const catGeneratorUrl = 'https://cataas.com/cat?json=true'

    this.axios
      .get(catGeneratorUrl)
      .then(({ data }) => {
        this.catImage = `https://cataas.com/${data.url}?height=${size}`
      })
  }
}
