
import { Options, Vue } from 'vue-class-component'
import BusinessCard from './components/BusinessCard.vue'

@Options({
  components: {
    BusinessCard
  }
})
export default class App extends Vue {}
